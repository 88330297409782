import { TextLinkProps } from '@/ts/interfaces/';
import classNames from '@/helpers/utils/classNames';
import styles from './styles/TextLink.module.scss';

const TextLink: React.FC<TextLinkProps> = ({
  children,
  dataLinkTracking,
  dataTmDataTrack,
  dataTrackName,
  dataTmDataTrackArticleId,
  dataTmDataTrackLocation,
  href,
  target,
  ariaLabel,
  noFollow,
  tabIndex = 0,
  title,
  role,
  isSubMenuItem,
  disabled,
  focusInternal,
}) => (
  <a
    className={`${classNames(
      styles,
      'text-link',
      `${isSubMenuItem && 'nav-curtain-submenu-item'}`,
      `${disabled && 'disabled'}`,
      `${href && !disabled && 'enabled'}`
    )} ${focusInternal ? 'tab-focus-internal' : 'tab-focus'}`}
    role={role}
    href={href}
    target={target}
    aria-label={ariaLabel}
    title={title}
    data-link-tracking={dataLinkTracking}
    data-tmdatatrack-name={dataTrackName}
    data-tmdatatrack={dataTmDataTrack}
    data-tmdatatrack-articleid={dataTmDataTrackArticleId}
    data-tmdatatrack-location={dataTmDataTrackLocation}
    rel={noFollow ? 'nofollow' : undefined}
    tabIndex={tabIndex}
  >
    {children}
  </a>
);

export default TextLink;
